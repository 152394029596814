import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import parseUuid from '@shoreag/helpers/parse-uuid';
import { Field } from 'react-final-form';
import Card from '@shoreag/base/Card';
import FormError from '../FormError';
import MappingValuesCard from '../MappingValuesCard';
import ColumnDictionaryPreview from '../ColumnDictionaryPreview';
import {
  END_OF_NAME_REGEX,
  LOCAL_STORAGE_ACTIONS,
} from '../../utilities/constants';
import { COLUMN_MAPPING_TYPES } from '../PipelineEditPage/utilities/constants';
import MappingConditionsFormSection from '../MappingConditionsFormSection';

const MappingHeaderSx = {
  color: 'textSubtle',
  fontSize: 0,
  letterSpacing: 2,
  marginBottom: 4,
  textTransform: 'uppercase',
};

const fieldSortFunction = (a, b) =>
  a.fieldName.toLowerCase() > b.fieldName.toLowerCase() ? 1 : -1;

const fuzzyUuidMatch = (id1, id2) =>
  parseUuid(id1).id === parseUuid(id2).id &&
  Number(parseUuid(id1).version) === Number(parseUuid(id2).version);

const recursivelyMapDatums = (datums, schemaId, pathPrefix = '') =>
  datums.reduce(
    (fieldNames, datum) =>
      fuzzyUuidMatch(datum.schemaVersionId, schemaId)
        ? fieldNames.concat(
            datum.definitionType === 'object'
              ? recursivelyMapDatums(
                  datums,
                  datum.nestedSchemaWithVersion.id,
                  `${pathPrefix}${datum.fieldName}${
                    datum.multiple ? '[]' : ''
                  }.`
                )
              : [
                  {
                    definitionType: datum.definitionType,
                    fieldName: `${pathPrefix}${datum.fieldName}`,
                    id: datum.id,
                    multiple: datum.multiple,
                  },
                ]
          )
        : fieldNames,
    []
  );

const ColumnMappingFormSection = ({
  arrayCounter,
  data,
  commonMapping,
  index,
  destinationSchema,
  fields,
  formContext,
  label,
  mappingRequired,
  mappingType,
  name,
  setArrayCounter,
  schemaStatus,
  recursive,
  sourceSchema,
}) => {
  const isGroupWiseMapping = !!mappingType;
  const columnDictionaryLocation = isGroupWiseMapping
    ? `${name}.columnMapping`
    : name;

  const [isSourceSchemaChanged, setIsSourceSchemaChanged] = useState(false);
  const [isDestinationSchemaChanged, setIsDestinationSchemaChanged] = useState(
    false
  );
  const [leftSelection, setLeftSelection] = useState(null);
  const [rightSelection, setRightSelection] = useState(null);
  const [selectedNestedData, setSelectedNestedData] = useState({
    left: null,
    right: null,
  });

  const changeColumnDictionary = ({ action, value: updatedMapping }) => {
    if (isGroupWiseMapping) {
      formContext.form.change(columnDictionaryLocation, [...updatedMapping]);
    } else {
      formContext.form.change(columnDictionaryLocation, updatedMapping);
    }
    if (action === LOCAL_STORAGE_ACTIONS.REMOVE) {
      formContext.form.change('columnDictionaryChanged', false);
    }
  };

  useEffect(() => {
    const allSourceColumns = [...data.left.map((r) => r.fieldName)];
    const allDestinationColumns = [...data.right.map((r) => r.fieldName)];
    const columnMappingLocation = isGroupWiseMapping
      ? name.replace(END_OF_NAME_REGEX, '.columnDictionary')
      : name;

    formContext.form.change(
      `${columnMappingLocation}AllDestinationColumns`,
      allDestinationColumns
    );

    if (isSourceSchemaChanged || isDestinationSchemaChanged) {
      let updatedMapping = [...columnDictionary];
      if (isSourceSchemaChanged) {
        updatedMapping = columnDictionary.filter((column) =>
          allSourceColumns.includes(column[0])
        );
      }
      if (isDestinationSchemaChanged) {
        updatedMapping = columnDictionary.filter((column) =>
          allDestinationColumns.includes(column[1])
        );
      }
      changeColumnDictionary(updatedMapping);
    }
  }, [data]);

  useEffect(() => {
    if (
      schemaStatus.isSourceSchemaChanged ||
      schemaStatus.isDestinationSchemaChanged
    ) {
      setIsSourceSchemaChanged(schemaStatus.isSourceSchemaChanged);
      setIsDestinationSchemaChanged(schemaStatus.isDestinationSchemaChanged);
    }
  }, [schemaStatus]);

  const columnDictionary = get(
    formContext.values,
    columnDictionaryLocation,
    []
  );
  const leftValues = columnDictionary.map((v) => v[0]);
  const rightValues = columnDictionary.map((v) => v[1]);

  const columnMappingValidation = (value) => {
    if (
      mappingRequired &&
      data.right.some(
        ({ fieldName }) => !(value || []).map((v) => v[1]).includes(fieldName)
      )
    ) {
      return 'All destination columns must be mapped';
    }
  };

  const setData = (dataList, schemaId) => {
    return recursive ? recursivelyMapDatums(dataList, schemaId) : dataList;
  };

  const handleIndexingOfSchema = (schema, isRight = false) => {
    let arrayPath;
    const schemaPath = schema?.path;

    /* eslint-disable */
    if (!commonMapping) {
      if (isRight) {
        arrayPath = fields?.value?.[index]?.right?.arrayPath;
      } else {
        arrayPath = fields?.value?.[index]?.left?.arrayPath;
      }
      const arrayPathWOIndex = arrayPath
        ? `${arrayPath.replace(/.\d+/g, '')}.`
        : '';
      const schemaPathWOParentPath =
        schemaPath && arrayPathWOIndex
          ? schemaPath.split(arrayPathWOIndex)[1]
          : '';
      arrayPath = [arrayPath, schemaPathWOParentPath]
        .filter((i) => i)
        .join('.');
    } else {
      arrayPath = schema.path;
    }
    /* eslint-enable */

    const currentCounter = arrayCounter[arrayPath]
      ? arrayCounter[arrayPath] + 1
      : 1;

    setArrayCounter({
      ...arrayCounter,
      [arrayPath]: arrayCounter[arrayPath] ? arrayCounter[arrayPath] + 1 : 1,
    });

    const fieldsToPush = isRight
      ? {
          right: {
            arrayPath: `${arrayPath}.${currentCounter}`,
            schemaPath,
          },
        }
      : {
          left: {
            arrayPath: `${arrayPath}.${currentCounter}`,
            schemaPath,
          },
        };

    fields.push(fieldsToPush);
  };

  return (
    <Card sx={{ bg: 'gray1', boxShadow: 'none', p: 4 }}>
      {label}
      {isGroupWiseMapping &&
        !commonMapping &&
        mappingType !== COLUMN_MAPPING_TYPES.FLAT && (
          <MappingConditionsFormSection
            formContext={formContext}
            mappingType={mappingType}
            name={name}
            schemaList={
              mappingType === COLUMN_MAPPING_TYPES.DISTRIBUTION
                ? setData(data.right, destinationSchema)
                : setData(data.left, sourceSchema)
            }
          />
        )}

      <Box display="flex">
        <Box mr="md" width="50%">
          <Box sx={MappingHeaderSx}>Available Source Columns</Box>
          <MappingValuesCard
            data={setData(data.left, sourceSchema)
              .filter((def) => {
                // return recursive || !leftValues.includes(def.fieldName)
                return !leftValues.includes(def.fieldName);
              })
              .sort(fieldSortFunction)}
            onDatumSelect={({ fieldName, label }) => {
              const arrayPath = fields?.value?.[index]?.left?.arrayPath;
              const arrayPathWOIndex = arrayPath
                ? `${arrayPath.replace(/.\d+/g, '')}.`
                : '';
              const schemaPath = fields?.value?.[index]?.left?.schema;
              const schemaPathWOParentPath =
                schemaPath && arrayPathWOIndex
                  ? schemaPath.split(arrayPathWOIndex)[1]
                  : '';

              let joinedField = '';
              if (arrayPath) {
                joinedField = [arrayPath, schemaPathWOParentPath, label]
                  .filter((i) => i)
                  .join('.');
              } else {
                joinedField = fieldName;
              }

              if (rightSelection) {
                if (isGroupWiseMapping) {
                  changeColumnDictionary({
                    action: LOCAL_STORAGE_ACTIONS.ADD,
                    value: [...columnDictionary, [joinedField, rightSelection]],
                  });
                } else {
                  fields.push([joinedField, rightSelection]);
                }
                setRightSelection(null);
              } else {
                setLeftSelection(joinedField);
              }
            }}
            onSchemaSelect={(schema) => {
              let isRecursive = false;
              const schemaPath = schema?.path;

              const currentSelectedSchema = data?.left?.find(
                (item) => item?.fieldName === schema?.label
              );

              if (!commonMapping) {
                const arrayPath = fields.value?.[index]?.left?.arrayPath;
                const arrayPathWOIndex = arrayPath
                  ? arrayPath.replace(/.\d+/g, '')
                  : '';
                isRecursive = currentSelectedSchema?.multiple
                  ? schemaPath === arrayPathWOIndex
                  : schemaPath.includes(arrayPathWOIndex);
              } else if (commonMapping && !currentSelectedSchema?.multiple) {
                setSelectedNestedData((prevState) => ({
                  ...prevState,
                  left: { schemaPath },
                }));
              }

              if (currentSelectedSchema?.multiple && !isRecursive) {
                return handleIndexingOfSchema(schema, false);
              }

              // eslint-disable-next-line
              fields.update(index, {
                ...fields?.value?.[index],
                left: {
                  ...fields?.value?.[index]?.left,
                  schemaPath,
                },
              });
            }}
            searchName="leftSearch"
            selectedDataPath={
              commonMapping || fields?.value?.[index]?.right
                ? selectedNestedData?.left?.schemaPath
                : fields?.value?.[index]?.left?.schemaPath
            }
          />
        </Box>
        <Box width="50%">
          <Box sx={MappingHeaderSx}>Available Destination Columns</Box>
          <MappingValuesCard
            data={setData(data.right, destinationSchema)
              .filter((def) => !rightValues.includes(def.fieldName))
              .sort(fieldSortFunction)}
            onDatumSelect={({ fieldName, label }) => {
              const arrayPath = fields?.value?.[index]?.right?.arrayPath;
              const arrayPathWOIndex = arrayPath
                ? `${arrayPath.replace(/.\d+/g, '')}.`
                : '';
              const schemaPath = fields?.value?.[index]?.right?.schema;
              const schemaPathWOParentPath =
                schemaPath && arrayPathWOIndex
                  ? schemaPath.split(arrayPathWOIndex)[1]
                  : '';

              let joinedField = '';
              if (arrayPath) {
                joinedField = [arrayPath, schemaPathWOParentPath, label]
                  .filter((i) => i)
                  .join('.');
              } else {
                joinedField = fieldName;
              }

              if (leftSelection) {
                if (isGroupWiseMapping) {
                  changeColumnDictionary({
                    action: LOCAL_STORAGE_ACTIONS.ADD,
                    value: [...columnDictionary, [leftSelection, joinedField]],
                  });
                } else {
                  fields.push([leftSelection, joinedField]);
                }
                setLeftSelection(null);
              } else {
                setRightSelection(joinedField);
              }
            }}
            onSchemaSelect={(schema) => {
              let isRecursive = false;
              const schemaPath = schema?.path;
              const currentSelectedSchema = data?.right?.find(
                (item) => item?.fieldName === schema?.label
              );

              if (!commonMapping) {
                const arrayPath = fields.value?.[index]?.right?.arrayPath;
                const arrayPathWOIndex = arrayPath
                  ? arrayPath.replace(/.\d+/g, '')
                  : '';

                isRecursive = currentSelectedSchema?.multiple
                  ? schemaPath === arrayPathWOIndex
                  : schemaPath.includes(arrayPathWOIndex);
              } else if (commonMapping && !currentSelectedSchema?.multiple) {
                setSelectedNestedData((prevState) => ({
                  ...prevState,
                  right: { schemaPath },
                }));
              }

              if (currentSelectedSchema?.multiple && !isRecursive) {
                return handleIndexingOfSchema(schema, true);
              }

              // eslint-disable-next-line
              fields.update(index, {
                ...fields?.value?.[index],
                right: {
                  ...fields?.value?.[index]?.right,
                  schemaPath,
                },
              });
            }}
            searchName="rightSearch"
            selectedDataPath={
              commonMapping || fields?.value?.[index]?.left
                ? selectedNestedData?.right?.schemaPath
                : fields?.value?.[index]?.right?.schemaPath
            }
          />
          <Field
            name={columnDictionaryLocation}
            validate={columnMappingValidation}
          >
            {({ meta }) => <FormError mt="sm">{meta.error}</FormError>}
          </Field>
        </Box>
      </Box>
      {(leftSelection || rightSelection || !!columnDictionary.length) && (
        <Card
          as="section"
          sx={{
            bg: 'gray3',
            boxShadow: 'none',
            pb: 4,
            pt: 0,
            px: 4,
          }}
        >
          <ColumnDictionaryPreview
            columnDictionary={columnDictionary}
            leftSelection={leftSelection}
            onRemove={(i) => {
              if (i === -1) {
                setLeftSelection(null);
                setRightSelection(null);
              } else if (isGroupWiseMapping) {
                columnDictionary.splice(i, 1);
                changeColumnDictionary({
                  action: LOCAL_STORAGE_ACTIONS.REMOVE,
                  value: columnDictionary,
                });
              } else {
                fields.remove(i);
              }
            }}
            rightSelection={rightSelection}
          />
        </Card>
      )}
    </Card>
  );
};

ColumnMappingFormSection.propTypes = {
  arrayCounter: PropTypes.shape({}),
  commonMapping: PropTypes.bool,
  data: PropTypes.shape({
    left: PropTypes.arrayOf(PropTypes.shape({})),
    right: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  destinationSchema: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    push: PropTypes.func,
    remove: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
    values: PropTypes.shape({}),
  }).isRequired,
  index: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  mappingRequired: PropTypes.bool.isRequired,
  mappingType: PropTypes.string,
  name: PropTypes.string.isRequired,
  recursive: PropTypes.bool.isRequired,
  schemaStatus: PropTypes.shape({
    isDestinationSchemaChanged: PropTypes.bool,
    isSourceSchemaChanged: PropTypes.bool,
  }),
  setArrayCounter: PropTypes.func,
  sourceSchema: PropTypes.string.isRequired,
};

ColumnMappingFormSection.defaultProps = {
  arrayCounter: {},
  commonMapping: true,
  fields: null,
  index: 0,
  label: null,
  mappingType: '',
  schemaStatus: {
    isDestinationSchemaChanged: false,
    isSourceSchemaChanged: false,
  },
  setArrayCounter: () => {},
};

export default ColumnMappingFormSection;
