import React from 'react';
import PropTypes from 'prop-types';
import AutoSave from '@shoreag/base/AutoSave';
import areJsonEqual from '../../../utilities/are-json-equal';
import setDataInLocalStorage from '../../../utilities/set-data-in-local-storage';
import { PIPELINE_AUTOSAVE_STORAGE_KEY } from '../utilities/constants';
import getPipelineStorageData from '../utilities/get-pipeline-local-storage-data';

const AutoSaveUpdatePipeline = ({ disabled, valuesString, pipelineId }) => {
  return (
    <AutoSave
      save={() => {
        const { isDataExists, pipelineStorageData } = getPipelineStorageData(
          pipelineId
        );
        const { values: localValues } =
          (isDataExists && pipelineStorageData) || {};
        const { data, values: formValues } = JSON.parse(valuesString);

        const onlyIfSomethingChanged =
          !disabled &&
          !(
            (formValues || localValues) &&
            areJsonEqual(formValues, localValues, 'lastUpdated')
          );
        setDataInLocalStorage({
          storageKey: PIPELINE_AUTOSAVE_STORAGE_KEY,
          targetKey: pipelineId,
          value: JSON.stringify({
            data,
            formChanged: onlyIfSomethingChanged,
            values: formValues,
          }),
        });
      }}
    />
  );
};

AutoSaveUpdatePipeline.propTypes = {
  disabled: PropTypes.bool,
  pipelineId: PropTypes.string.isRequired,
  valuesString: PropTypes.string.isRequired,
};

AutoSaveUpdatePipeline.defaultProps = {
  disabled: false,
};

export default AutoSaveUpdatePipeline;
